@keyframes blink {
  50% {
    opacity: 0;
  }
}

.blinking {

  color: red !important;
  animation: blink 1s step-start 0s infinite;
}

.time-30s-blinking {
  animation: blink 1.5s step-start 0s infinite;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f7f7f7;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

#timer {
  text-align: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

h2 {
  font-size: 24px;
  color: #555;
  margin-bottom: 10px;
}

h1 {
  font-size: 100px; /* 增大字体大小 */
  color: #222;
  margin: 20px 0;
}

h3 {
  font-size: 20px;
  color: #444;
}

button {
  font-size: 20px;
  padding: 10px 20px;
  margin: 10px 10px 20px 10px;
  border: none;
  border-radius: 5px;
  background-color: #5cb85c;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:not(:disabled):hover {
  background-color: #4cae4c;
}

/* 控制按钮的横向布局 */
.controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* 针对两个计时器的布局 */
.timer-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}

.timer-box {
  text-align: center;
}

.timer-box h1 {
  font-size: 80px; /* 增大字体大小 */
  margin: 0;
}

/* 新增的横向排列样式 */
.debate-timers-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 确保每个计时器盒子有足够的空间并且居中显示 */
.timer-box {
  flex: 1;
  text-align: center;
  margin: 0 10px; /* 根据需要调整间距 */
}

@media screen and (max-width: 768px) {
  /* 在较小的屏幕上，计时器应该垂直排列以避免拥挤 */
  .debate-timers-container {
    flex-direction: column;
  }
}


@media screen and (max-width: 768px) {
  .timer-container {
    flex-direction: column;
  }

  .timer-box {
    margin-bottom: 20px;
  }

  /* 适应小屏幕的计时器字体大小 */
  .timer-box h1 {
    font-size: 60px; /* 调整为合适的大小 */
  }
}

/* 深色模式下的背景色 */
.dark-mode {
  background-color: #333 !important;
  color: #f7f7f7 !important;
}

/* 深色模式下的计时器样式 */
.dark-mode #timer {
  background-color: #333; /* 深色模式下的背景色 */
  color: #f7f7f7; /* 深色模式下的文字颜色 */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1); /* 深色模式下的阴影效果 */
}

/* 深色模式下的计时器样式 */
.dark-mode h2 {
  color: #f7f7f7; /* 深色模式下的文字颜色 */
}

/* 其他深色模式样式 */
.dark-mode select {
  background-color: #3c3c3c;
  color: #f7f7f7;
  border: 1px solid #555;
}

/* 黑暗模式下的按钮样式 */
.dark-mode button {
  background-color: #4c4c4c;
  color: #f7f7f7;
  border: 2px solid transparent; /* 添加透明边框，以便在激活状态下更改 */
}

/* 黑暗模式下按钮激活状态的样式 */
.dark-mode button.active {
  border-color: #f7f7f7; /* 激活状态的边框颜色 */
  background-color: #666; /* 激活状态的背景颜色 */
  color: #fff; /* 可以调整文本颜色以提高对比度 */
}

/* 黑暗模式下按钮不可用状态的样式 */
.dark-mode button:disabled {
  background-color: #3c3c3c;
  color: #555;
}

/* 黑暗模式下按钮悬停状态的样式 */
.dark-mode button:not(:disabled):hover {
  background-color: #5c5c5c;
  border-color: #f7f7f7; /* 悬停状态下的边框颜色 */
}

/* 深色模式下的计时器样式 */
.dark-mode .timer-box h3 {
  color: #f7f7f7;
}

/* 深色模式下的计时器样式 */
.dark-mode .timer-box h1 {
  color: #f7f7f7;
}

